import { Address } from "viem";
import { Loan } from "./Loan";
import LoanAuctioned from "./LoanAuctioned";
import { faker } from "@faker-js/faker";
import { externalWalletModule } from "clients/verisModule";
import NftFactory from "../nft/NftFactory";
import { app } from "app.config";
import { INft } from "../nft/INft";
import { MarketItemType } from "contracts/MarketContract";

export default class LoanFactory {
  static create({
    id,
    nfts,
    borrower,
    currentBorrowAmount,
    underlyingAsset,
    lastSalePrice,
  }: {
    id: Address;
    nfts: INft[];
    borrower: Address;
    currentBorrowAmount: bigint;
    underlyingAsset: Address;
    lastSalePrice?: bigint;
  }): Loan {
    const nftMarketItemIndex = nfts.findIndex(
      (nft) =>
        nft.marketItemData !== undefined &&
        nft.marketItemData.type === MarketItemType.TYPE_LIQUIDATION_AUCTION &&
        nft.marketItemData.bids.length > 0
    );

    return nftMarketItemIndex !== -1
      ? new LoanAuctioned({
          id,
          nfts,
          borrower,
          underlyingAsset,
          currentBorrowAmount,
          redeemEnd: nfts[nftMarketItemIndex].marketItemData!.biddingEnd,
          lastSalePrice,
        })
      : new Loan({
          id,
          nfts,
          borrower,
          currentBorrowAmount,
          underlyingAsset,
          lastSalePrice,
        });
  }

  static createRandomLoan(): Loan {
    const id: Address = `0x${faker.string.uuid()}`;
    const nfts: INft[] = Array.from(
      { length: faker.number.int({ min: 1, max: 5 }) },
      () => NftFactory.createRandomNft()
    );
    const borrower = externalWalletModule.address!;
    const currentBorrowAmount = BigInt(faker.number.int({ min: 7, max: 8 }));
    const underlyingAsset = app.CONTRACT_ADDRESSES.weth;
    const lastSalePrice = faker.number.bigInt({ min: 1e7, max: 1e8 });

    return this.create({
      id,
      nfts,
      borrower,
      currentBorrowAmount,
      underlyingAsset,
      lastSalePrice,
    });
  }

  static createRandomAuctionedLoan(): LoanAuctioned {
    const id: Address = `0x${faker.string.uuid()}`;
    const nfts: INft[] = Array.from(
      { length: faker.number.int({ min: 1, max: 5 }) },
      () => NftFactory.createRandomNft()
    );
    const borrower = externalWalletModule.address!;
    const currentBorrowAmount = BigInt(
      faker.number.int({ min: 1e14, max: 1e18 })
    );
    const underlyingAsset = app.CONTRACT_ADDRESSES.weth;
    const redeemEnd = faker.date.future().getTime();
    const lastSalePrice = faker.number.bigInt({ min: 1e14, max: 1e18 });

    return new LoanAuctioned({
      id,
      nfts,
      borrower,
      underlyingAsset,
      currentBorrowAmount,
      redeemEnd,
      lastSalePrice,
    });
  }
}
