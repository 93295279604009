import { ERC721Contract } from "../../../contracts/ERC721Contract";
import { Attribute } from "../../types/marketplace/MarketItemDetailed";
import { ServerError, ClientError, UnexpectedError } from "../../../errors";
import { AttributeFromReservoir } from "./getAttributesDataFromReservoir.server";
import { SimpleNft } from "logic/types/nft/INft";

export default async function getNftAttributes(
  nft: SimpleNft
): Promise<Attribute[]> {
  const url = `/api/collections/${nft.collection}/tokens/${nft.tokenId}/attributes`;

  const response = await fetch(url);

  if (!response.ok) {
    const body = await response.text();

    if (response.status > 499) throw new ServerError(body);
    else if (response.status > 399) throw new ClientError(body);

    throw new UnexpectedError(body);
  }

  const totalSupply = await new ERC721Contract(nft.collection).totalSupply();

  const attributes = (await response.json()) as AttributeFromReservoir[];

  return attributes.map(({ floorAskPrices, key, tokenCount, value }) => {
    const floorPrice =
      floorAskPrices.length > 0 ? BigInt(floorAskPrices[0] * 1e18) : BigInt(0);

    return {
      floorPrice,
      key,
      value,
      rarity: (BigInt(tokenCount) * BigInt(10000)) / totalSupply,
    };
  });
}
