import { Address } from "viem";
import { MarketItemType } from "../../../contracts/MarketContract";
import parseAndSortBidsFromSubgraph from "../../helpers/parseAndSortBidsFromSubgraph";
import { Loan } from "../loan/Loan";
import { MarketItem } from "./MarketItem";
import { Attribute, MarketItemDetailed } from "./MarketItemDetailed";
import { MyActivityItem } from "./MyActivityItem";
import { Bid } from "./Bid";
import { MarketItemFromServer } from "../../UnlockdService";
import TheGraph from "../../../thegraph";
import { areTheSameNft } from "@unlockdfinance/verislabs-web3/utils";
import { INft, NftWithImageAndName } from "../nft/INft";

// MyActivity -> SimpleMarket
// MarketItem -> SimpleMarket
// MarketItemDetailed -> MarketItem

type MarketItemOrDetailed<T extends MarketItem | MarketItemDetailed> =
  T extends MarketItemDetailed ? MarketItemDetailed : MarketItem;

export default class MarketItemFactory {
  static createMarketItem({
    _buyNowPrice,
    loan,
    marketItemFromServer,
    minBid,
    nft,
    reservoirBid,
  }: {
    marketItemFromServer: MarketItemFromServer;
    nft: INft;
    loan: Loan;
    reservoirBid?: bigint | null;
    minBid?: bigint;
    _buyNowPrice?: bigint;
  }): MarketItem {
    this.validateCreateMarketItem(
      marketItemFromServer.type,
      loan,
      nft,
      minBid,
      _buyNowPrice
    );

    const minBidCorrected = this.correctMinBid(minBid);

    const id = marketItemFromServer.id;
    const type = marketItemFromServer.type;
    const bids = parseAndSortBidsFromSubgraph(marketItemFromServer.bids);
    const status = TheGraph.MarketItemStatus.ACTIVE;
    const biddingEnd = Number(`${marketItemFromServer.endTime}000`);
    const loanId = marketItemFromServer.loan.id;
    const owner = marketItemFromServer.owner;
    const assetId = marketItemFromServer.assetId;

    return new MarketItem({
      biddingEnd,
      owner,
      assetId,
      bids,
      id,
      loanId,
      status,
      type,
      loan,
      nft,
      reservoirBid,
      minBid: minBidCorrected,
      _buyNowPrice,
      _doc: marketItemFromServer,
    });
  }

  static createMyActivityItem({
    nft,
    subgraphItem,
  }: {
    subgraphItem: TheGraph.MarketItem;
    nft: NftWithImageAndName;
  }): MyActivityItem {
    this.validateCreateMyActivityItem(subgraphItem);

    const type = subgraphItem.orderType;
    const id = subgraphItem.id;
    const bids = parseAndSortBidsFromSubgraph(subgraphItem.bids);
    const status = subgraphItem.status;
    const biddingEnd = Number(`${subgraphItem.endTime}000`);
    const loanId = subgraphItem.loan.id;
    const assetId = subgraphItem.assetId;
    const owner = subgraphItem.seller;
    const lastStatusChangedTimestamp = Number(`${subgraphItem.date}000`);
    const doc = subgraphItem;
    const buyer = subgraphItem.buyer as Address;

    const latestBid = BigInt(subgraphItem.bidAmount || "0");
    const soldPrice = BigInt(subgraphItem.buyerAmount || "0");

    return new MyActivityItem({
      assetId,
      biddingEnd,
      bids,
      buyer,
      id: id as Address,
      lastStatusChangedTimestamp,
      latestBid,
      loanId: loanId as Address,
      nft,
      owner,
      soldPrice,
      status,
      type: type as MarketItemType,
      doc,
    });
  }

  private static validateCreateMyActivityItem(
    subgraphItem: TheGraph.MarketItem
  ) {
    if (
      subgraphItem.status === TheGraph.MarketItemStatus.BOUGHT &&
      !subgraphItem.buyer
    ) {
      throw new Error("buyer is required for bought status");
    }
  }

  static cloneMarketItemWithNewBid<T extends MarketItem | MarketItemDetailed>(
    marketItem: T,
    bid: Bid,
    minBid: bigint,
    id: Address
  ): MarketItemOrDetailed<T> {
    const bids = [...marketItem.bids, bid];

    const minBidCorrected = this.correctMinBid(minBid);

    return marketItem instanceof MarketItemDetailed
      ? new MarketItemDetailed({
        id,
        ...marketItem,
        bids,
        minBid: minBidCorrected,
      })
      : (new MarketItem({
        id,
        ...marketItem,
        bids,
        minBid: minBidCorrected,
      }) as MarketItemOrDetailed<T>);
  }

  static createMarketItemDetailed({
    attributes,
    loan,
    nft,
    subgraphItem,
    buyNowPrice,
    minBid,
    serverItem,
    reservoirBid,
  }: {
    subgraphItem?: TheGraph.MarketItem;
    serverItem?: MarketItemFromServer;
    nft: INft;
    loan: Loan;
    attributes: Attribute[];
    reservoirBid?: bigint | null;
    minBid?: bigint;
    buyNowPrice?: bigint;
  }) {
    if ((!subgraphItem && !serverItem) || (subgraphItem && serverItem)) {
      throw new Error("subgraphItem or serverItem is required");
    }

    this.validateCreateMarketItem(
      subgraphItem
        ? (subgraphItem.orderType as MarketItemType)
        : serverItem!.type,
      loan,
      nft,
      minBid,
      buyNowPrice
    );

    const minBidCorrected = this.correctMinBid(minBid);

    let type: MarketItemType;
    let id: Address | undefined;
    let status: TheGraph.MarketItemStatus;
    let owner: Address;

    if (subgraphItem) {
      type = subgraphItem.orderType as MarketItemType;
      id = subgraphItem.id as Address;
      status = subgraphItem.status;
      owner = subgraphItem.seller;
    } else {
      type = serverItem!.type;
      status = TheGraph.MarketItemStatus.ACTIVE;
      owner = serverItem!.owner;
    }

    const bids = parseAndSortBidsFromSubgraph(
      subgraphItem?.bids || serverItem!.bids
    );

    const loanId = (subgraphItem?.loan.id || serverItem!.loan.id) as Address;
    const assetId = (subgraphItem?.assetId || serverItem!.assetId) as Address;
    const biddingEnd = Number(
      `${subgraphItem?.endTime || serverItem!.endTime}000`
    );

    const _doc = subgraphItem || serverItem!;

    return new MarketItemDetailed({
      assetId,
      attributes,
      biddingEnd,
      bids,
      _doc,
      loan,
      loanId: loanId as Address,
      nft,
      owner,
      status,
      type: type as MarketItemType,
      _buyNowPrice: buyNowPrice,
      id,
      minBid: minBidCorrected,
      reservoirBid,
    });
  }

  private static validateCreateMarketItem(
    type: MarketItemType,
    loan: Loan,
    nft: INft,
    minBid?: bigint,
    buyNowPrice?: bigint
  ) {
    if (minBid === undefined && type !== MarketItemType.TYPE_FIXED_PRICE) {
      throw new Error("minBid is required for auction type");
    } else if (
      buyNowPrice === undefined &&
      (type === MarketItemType.TYPE_FIXED_PRICE ||
        type === MarketItemType.TYPE_FIXED_PRICE_AND_AUCTION)
    ) {
      throw new Error("buyNowPrice is required for fixed price type");
    } else if (!loan.nfts.find((_nft) => areTheSameNft(_nft, nft))) {
      throw new Error("nft provided is not included in the loan");
    }
  }

  private static correctMinBid(minBid: bigint | undefined) {
    return minBid ? (minBid * BigInt(101)) / BigInt(100) : undefined;
  }
}
