import { WatchContractEventReturnType } from "viem";
import { Address } from "viem";
import {
  auctionContract,
  auctionEventsContract,
  actionContract,
  marketContract,
  marketEventsContract,
  uTokenFactoryContract,
} from "../contracts";
import { RedeemArgs, RedeemTopics } from "../contracts/AuctionEventsContract";
import { LoanDataFromContract } from "../contracts/ActionContract";
import { MarketItemType } from "../contracts/MarketContract";
import {
  BidArgs,
  BidTopics,
  BoughtTopics,
  CancelArgs,
  CancelTopics,
} from "../contracts/MarketEventsContract";
import TheGraph from "../thegraph";
import Module from "./Module";
import unlockdService, {
  LoanFromServer,
  PaginationFromServer,
} from "./UnlockdService";
import getMaximumBidFromReservoir, {
  ReservoirBid,
} from "./helpers/getMaximumBidFromReservoir";
import getNftsMetadata from "./helpers/nfts/getNftsMetadata";
import getNftAttributes from "./helpers/nfts/getNftAttributes";
import getNftFromNftId from "./helpers/nfts/parseNftId";
import {
  MarketItem,
  MarketItemDetailed,
  MyActivityItem,
  Pagination,
  TransactionHistoryItem,
} from "./types";
import { Loan } from "./types/loan/Loan";
import LoanFactory from "./types/loan/LoanFactory";
import MarketItemFactory from "./types/marketplace/MarketItemFactory";
import NftFactory from "./types/nft/NftFactory";
import { externalWalletModule } from "../clients/verisModule";
import { areTheSameNft } from "@unlockdfinance/verislabs-web3/utils";
import { app } from "app.config";
import { Attribute } from "./types/marketplace/MarketItemDetailed";
import { MarketItemData, SimpleNft } from "./types/nft/INft";
import { calculateLoanValuation, calculateLtv } from "./helpers/math";
import parseAndSortBidsFromSubgraph from "./helpers/parseAndSortBidsFromSubgraph";

export type Discount = {
  loanAuctioned: number;
  loanListed: number;
};

export enum OrderBy {
  END_TIME = "endTime",
}

export enum Sort {
  ASC = "ASC",
  DESC = "DESC",
}

export type MarketplaceFilter = {
  // orderBy: "bidPrice" | "end-time";
  orderBy: OrderBy;
  sort: Sort;
  // the collectionSelected number could be the index in the array of collections
  // of the collectionSelected
  ended: boolean;
  collections?: number;
};

export type MarketplaceFilterKeys = keyof MarketplaceFilter;

class MarketplaceModule extends Module {
  private discounts: Discount | null = null;

  async retrieveItems(
    offset: number,
    limit: number,
    filter: MarketplaceFilter
  ): Promise<{ items: MarketItem[]; areMoreItemsToLoad: boolean } | null> {
    const processId = this.startNewProcess();

    let { orders } = await unlockdService.retrieveOrders(
      limit + 1,
      offset,
      filter
    );

    if (orders.length === 0) return { items: [], areMoreItemsToLoad: false };

    const areMoreItemsToLoad = !!orders[limit];

    orders = orders.slice(0, limit);

    const nftsToRequestImages: SimpleNft[] = [];
    const currentBorrowAmountPromises: Promise<bigint>[] = [];
    const reservoirBidPromises: Promise<ReservoirBid | null>[] = [];
    const minBidsPromises: (Promise<bigint> | undefined)[] = [];
    const buyNowPromises: (Promise<bigint> | undefined)[] = [];

    orders.forEach((order) => {
      if (order.loan.nfts.length === 0) {
        throw new Error("Order without assets");
      }

      const nft = {
        collection: order.nft.collection,
        tokenId: order.nft.tokenId,
      };

      nftsToRequestImages.push(nft);

      currentBorrowAmountPromises.push(
        uTokenFactoryContract.getDebtFromLoanId(
          order.loan.underlyingAsset,
          order.loan.id
        )
      );

      reservoirBidPromises.push(getMaximumBidFromReservoir(nft));

      const nftsWithPrices: { valuation: bigint; ltv: bigint }[] =
        order.loan.nfts
          .map((nft) => ({
            ...nft,
            valuation: BigInt(nft.valuation),
            ltv: BigInt(nft.ltv),
          }))
          .filter(
            (_nft) =>
              !areTheSameNft(
                {
                  collection: _nft.collection as Address,
                  tokenId: _nft.tokenId,
                },
                nft
              )
          );

      const aggValuation =
        nftsWithPrices.length > 0
          ? calculateLoanValuation(nftsWithPrices)
          : BigInt(0);

      const aggLtv =
        nftsWithPrices.length > 0 ? calculateLtv(nftsWithPrices) : BigInt(0);

      minBidsPromises.push(
        order.type !== MarketItemType.TYPE_FIXED_PRICE
          ? this.getMinBidPrice(
              order.type,
              order.loan.underlyingAsset,
              aggValuation,
              aggLtv,
              order.loan.id,
              BigInt(order.nft.valuation),
              order.assetId,
              order.id
            )
          : undefined
      );

      buyNowPromises.push(
        order.type !== MarketItemType.TYPE_AUCTION &&
          order.type !== MarketItemType.TYPE_LIQUIDATION_AUCTION &&
          order.id
          ? this.getBuyNowPrice(
              order.id,
              order.loan.underlyingAsset,
              aggValuation,
              aggLtv
            )
          : undefined
      );
    });

    if (!this.isProcessOn(processId)) {
      return null;
    }

    const [
      nftsImages,
      currentBorrowAmounts,
      reservoirBids,
      minBids,
      buyNowPrices,
    ] = await Promise.all([
      getNftsMetadata(nftsToRequestImages),
      Promise.all(currentBorrowAmountPromises),
      Promise.all(reservoirBidPromises),
      Promise.all(minBidsPromises),
      Promise.all(buyNowPromises),
    ]);

    // const nftsImages = await getNftsImages(nftsToRequestImages);

    // const currentBorrowAmounts = await Promise.all(currentBorrowAmountPromises);

    // const reservoirBids = await Promise.all(reservoirBidPromises);

    // const minBids = await Promise.all(minBidsPromises);

    // const buyNowPrices = await Promise.all(buyNowPromises);

    const items = orders.map((order, i) => {
      const marketItemData = {
        id: order.id as Address,
        type: order.type as MarketItemType,
        biddingEnd: Number(`${order.endTime}000`),
        bids: parseAndSortBidsFromSubgraph(order.bids),
      };

      const nft = NftFactory.create({
        collection: order.nft.collection,
        tokenId: order.nft.tokenId,
        isDeposited: true,
        ltv: BigInt(order.nft.ltv),
        valuation: BigInt(order.nft.valuation),
        owner: order.owner,
        image: nftsImages[i].image,
        attributes: [],
        marketItemData,
        liquidationThreshold: app.DEFAULT_LIQ_THRESHOLD_VALUE,
      });

      const nfts = order.loan.nfts.map((_nft) => {
        let _marketItemData: MarketItemData | undefined;

        if (
          areTheSameNft(
            { collection: _nft.collection, tokenId: _nft.tokenId },
            nft
          )
        ) {
          _marketItemData = marketItemData;
        }

        return NftFactory.create({
          collection: _nft.collection,
          tokenId: _nft.tokenId,
          isDeposited: true,
          ltv: BigInt(_nft.ltv),
          valuation: BigInt(_nft.valuation),
          image: "",
          owner: order.owner,
          marketItemData: _marketItemData,
          attributes: [],
          liquidationThreshold: app.DEFAULT_LIQ_THRESHOLD_VALUE,
        });
      });

      const loan = LoanFactory.create({
        id: order.loan.id,
        nfts,
        borrower: order.owner,
        underlyingAsset: order.loan.underlyingAsset,
        currentBorrowAmount: currentBorrowAmounts[i],
      });

      const reservoirBidAmount =
        reservoirBids[i] !== null ? reservoirBids[i]!.amount : null;

      return MarketItemFactory.createMarketItem({
        marketItemFromServer: order,
        nft,
        loan,
        reservoirBid: reservoirBidAmount,
        minBid: minBids[i],
        _buyNowPrice: buyNowPrices[i],
      });
    });

    return this.isProcessOn(processId) ? { items, areMoreItemsToLoad } : null;
  }

  async areItemsInMarketplace(): Promise<boolean> {
    const [{ orders: currentOrders }, { orders: endedOrders }] =
      await Promise.all([
        unlockdService.retrieveOrders(1, 0, {
          sort: Sort.DESC,
          orderBy: OrderBy.END_TIME,
          ended: false,
        }),
        unlockdService.retrieveOrders(1, 0, {
          sort: Sort.DESC,
          orderBy: OrderBy.END_TIME,
          ended: true,
        }),
      ]);

    return currentOrders.length > 0 || endedOrders.length > 0;
  }

  async retrieveItem(id: string): Promise<MarketItemDetailed | null> {
    if (this.isOrderId(id)) {
      const data = await TheGraph.client.getMarketItem({ id });

      const itemFromSubgraph = data.order as TheGraph.MarketItem;

      if (
        !itemFromSubgraph ||
        itemFromSubgraph === null ||
        itemFromSubgraph.status !== TheGraph.MarketItemStatus.ACTIVE
      ) {
        return null;
      }

      const order = itemFromSubgraph;

      const nftToRequestImage = {
        collection: order.collection,
        tokenId: order.tokenId,
      };

      const [{ image }] = await getNftsMetadata([nftToRequestImage]);

      const nftsToRequestPrices = order.loan.assets.map((nft) => ({
        collection: nft.collection,
        tokenId: nft.tokenId,
        underlyingAsset: order.loan.underlyingAsset,
      }));

      const nftPrices = await unlockdService.getNftsPrices(nftsToRequestPrices);

      const nftPrice = nftPrices.find((_nft) =>
        areTheSameNft(_nft, nftToRequestImage)
      );

      if (!nftPrice) {
        throw new Error("Nft price not found");
      }

      const nftPricesFiltered = nftPrices.filter(
        (_nft) => !areTheSameNft(_nft, nftToRequestImage)
      );

      const aggValuation =
        nftPricesFiltered.length > 0
          ? calculateLoanValuation(nftPricesFiltered)
          : BigInt(0);

      const aggLtv =
        nftPricesFiltered.length > 0
          ? calculateLtv(nftPricesFiltered)
          : BigInt(0);

      let attributes: Attribute[] = [];

      try {
        attributes = await getNftAttributes(nftToRequestImage);
      } catch (err) {
        console.log("Error getting attributes", err);
      }

      const currentBorrowAmount = await uTokenFactoryContract.getDebtFromLoanId(
        order.loan.underlyingAsset,
        order.loanId
      );

      const reservoirBid = await getMaximumBidFromReservoir(nftToRequestImage);

      const marketItemData = {
        id: order.id as Address,
        type: order.orderType as MarketItemType,
        biddingEnd: Number(`${order.endTime}000`),
        bids: parseAndSortBidsFromSubgraph(order.bids),
      };

      const nft = NftFactory.create({
        collection: order.collection,
        tokenId: order.tokenId,
        isDeposited: true,
        ltv: nftPrice.ltv,
        valuation: nftPrice.valuation,
        marketItemData,
        image,
        owner: order.seller,
        attributes: [],
        liquidationThreshold: app.DEFAULT_LIQ_THRESHOLD_VALUE,
      });

      const nfts = order.loan.assets.map((_nft, index) => {
        let _marketItemData: MarketItemData | undefined;

        if (areTheSameNft(_nft, nft)) {
          _marketItemData = marketItemData;
        }

        return NftFactory.create({
          collection: _nft.collection,
          tokenId: _nft.tokenId,
          isDeposited: true,
          ltv: BigInt(nftPrices[index].ltv),
          valuation: BigInt(nftPrices[index].valuation),
          image: "",
          owner: order.seller,
          marketItemData: _marketItemData,
          attributes: [],
          liquidationThreshold: app.DEFAULT_LIQ_THRESHOLD_VALUE,
        });
      });

      const loan = LoanFactory.create({
        id: order.loanId,
        nfts,
        borrower: order.seller,
        underlyingAsset: order.loan.underlyingAsset,
        currentBorrowAmount: currentBorrowAmount,
      });

      const reservoirBidAmount =
        reservoirBid !== null ? reservoirBid!.amount : null;

      let buyNowPrice: bigint | undefined;

      if (
        order.orderType !== MarketItemType.TYPE_AUCTION &&
        order.orderType !== MarketItemType.TYPE_LIQUIDATION_AUCTION
      ) {
        buyNowPrice = await this.getBuyNowPrice(
          order.id as Address,
          order.loan.underlyingAsset,
          nft.valuation,
          nft.ltv
        );
      }

      let minBid: bigint | undefined;

      if (order.orderType !== MarketItemType.TYPE_FIXED_PRICE) {
        minBid = await this.getMinBidPrice(
          order.orderType as MarketItemType,
          loan.underlyingAsset,
          aggValuation,
          aggLtv,
          loan.id,
          nft.valuation,
          order.assetId,
          order.id as Address | undefined
        );
      }

      return MarketItemFactory.createMarketItemDetailed({
        subgraphItem: order,
        nft,
        loan,
        attributes,
        reservoirBid: reservoirBidAmount,
        minBid,
        buyNowPrice,
      });
    } else {
      const { collection, tokenId } = getNftFromNftId(id);

      const itemFromServer = await unlockdService.retrieveOrder(
        collection,
        tokenId
      );

      if (itemFromServer === null) return null;

      const order = itemFromServer;

      const currentBorrowAmount = await uTokenFactoryContract.getDebtFromLoanId(
        itemFromServer.loan.underlyingAsset,
        order.loan.id
      );

      const nftToRequestImage = {
        collection: order.nft.collection,
        tokenId: order.nft.tokenId,
      };

      const [{ image }] = await getNftsMetadata([nftToRequestImage]);

      const attributes = await getNftAttributes(nftToRequestImage);

      const nft = NftFactory.create({
        collection: order.nft.collection,
        tokenId: order.nft.tokenId,
        isDeposited: true,
        ltv: BigInt(order.nft.ltv),
        valuation: BigInt(order.nft.valuation),
        image,
        owner: order.owner,
        attributes: [],
        liquidationThreshold: app.DEFAULT_LIQ_THRESHOLD_VALUE,
      });

      const nfts = order.loan.nfts.map((_nft, index) => {
        return NftFactory.create({
          collection: _nft.collection,
          tokenId: _nft.tokenId,
          isDeposited: true,
          ltv: BigInt(_nft.ltv),
          valuation: BigInt(_nft.valuation),
          owner: order.owner,
          image: "",
          attributes: [],
          liquidationThreshold: app.DEFAULT_LIQ_THRESHOLD_VALUE,
        });
      });

      const loan = LoanFactory.create({
        id: order.loan.id,
        nfts,
        borrower: order.owner,
        underlyingAsset: order.loan.underlyingAsset,
        currentBorrowAmount: currentBorrowAmount,
      });

      const nftsFiltered = nfts
        .filter((_nft) => !areTheSameNft(_nft, nft))
        .map((_nft) => ({
          valuation: BigInt(_nft.valuation),
          ltv: BigInt(_nft.ltv),
          collection: _nft.collection,
          tokenId: _nft.tokenId,
        }));

      const aggValuation =
        nftsFiltered.length > 0
          ? calculateLoanValuation(nftsFiltered)
          : BigInt(0);

      const aggLtv =
        nftsFiltered.length > 0 ? calculateLtv(nftsFiltered) : BigInt(0);

      const minBid = await this.getMinBidPrice(
        order.type as MarketItemType,
        order.loan.underlyingAsset,
        aggValuation,
        aggLtv,
        order.loan.id,
        BigInt(order.nft.valuation),
        order.assetId,
        order.id as Address | undefined
      );

      return MarketItemFactory.createMarketItemDetailed({
        serverItem: order,
        nft,
        loan,
        attributes,
        minBid,
      });
    }
  }

  async getBuyNowPrice(
    id: Address,
    underlyingAsset: Address,
    valuation: bigint,
    ltv: bigint
  ) {
    return marketContract.getBuyNowPrice(id, underlyingAsset, valuation, ltv);
  }

  private isOrderId(id: string) {
    return !id.includes("-");
  }

  async getMinBidPrice(
    type: MarketItemType,
    underlyingAsset: Address,
    aggValuation: bigint,
    aggLtv: bigint,
    loanId: Address,
    nftValuation: bigint,
    assetId: Address,
    id?: Address
  ) {
    if (type === MarketItemType.TYPE_FIXED_PRICE) {
      throw new Error("Fixed price doesn't have min bid");
    } else if (type === MarketItemType.TYPE_LIQUIDATION_AUCTION) {
      return auctionContract.getMinBidPriceAuction(
        loanId,
        assetId,
        nftValuation,
        aggValuation,
        aggLtv
      );
    } else {
      if (!id) {
        throw new Error("id is required for this type of marketItem min bid");
      }
      return marketContract.getMinBidPrice(
        id,
        underlyingAsset,
        aggValuation,
        aggLtv
      );
    }
  }

  private async retrieveTransactionHistoryFromSubgraph(
    skip: number,
    first: number
  ) {
    const data = await TheGraph.client.getTransactionHistory({
      skip: skip,
      first: first,
    });

    if (!data.orders)
      throw new Error(`Error fetching information from the graph`);

    return data.orders;
  }

  async retrieveTransactionsHistory(
    skip: number,
    first: number
  ): Promise<{
    items: TransactionHistoryItem[];
    pagination: Pagination;
  }> {
    const orders = await this.retrieveTransactionHistoryFromSubgraph(
      skip,
      first
    );

    if (orders.length === 0)
      return {
        items: [],
        pagination: { offset: skip, limit: first, continue: false },
      };

    const nftsWithImagesAndName = await getNftsMetadata(
      orders.map(({ collection, tokenId }) => ({
        collection,
        tokenId,
      }))
    );

    const items = orders.map(
      (order, i) => new TransactionHistoryItem(order, nftsWithImagesAndName[i])
    );

    const nextOrders = await this.retrieveTransactionHistoryFromSubgraph(
      first + skip,
      first
    );

    return {
      items,
      pagination: {
        offset: skip,
        limit: first,
        continue: nextOrders.length > 0,
      },
    };
  }

  private async retrieveMyActivityFromSubgraph(skip: number, first: number) {
    if (!externalWalletModule.address) throw new Error("No address connected");

    const data = await TheGraph.client.getMyActivity({
      first,
      skip,
      user: externalWalletModule.address!,
    });

    if (!data) {
      throw new Error(`Error fetching information from the graph.`);
    }

    return data.orders as TheGraph.MarketItem[];
  }

  retrieveMyActivity = async (
    skip: number = 0,
    first: number = 100
  ): Promise<{ items: Array<MyActivityItem>; pagination: Pagination }> => {
    const orders = await this.retrieveMyActivityFromSubgraph(skip, first);

    if (orders.length === 0) {
      return {
        items: [],
        pagination: { offset: skip, limit: first, continue: false },
      };
    }

    const nextOrders = await this.retrieveMyActivityFromSubgraph(
      first + skip,
      first
    );

    const nfts = await getNftsMetadata(
      orders.map(({ collection, tokenId }) => ({
        collection,
        tokenId,
      }))
    );

    const items = orders.map((order, index) =>
      MarketItemFactory.createMyActivityItem({
        subgraphItem: order,
        nft: nfts[index],
      })
    );

    return {
      items,
      pagination: { offset: 0, limit: 10, continue: nextOrders.length !== 0 },
    };
  };

  async getDiscount(): Promise<Discount> {
    if (this.discounts) return this.discounts;

    const discounts = {
      loanAuctioned: 0,
      loanListed: 0,
    };

    // TODO: THIS WILL BE IMPLEMENTED AFTER V2 RELEASE
    // const [loanAuctionedValue, loanListedValue] = await Promise.all([
    //   lockeysManagerContract.getLockeyDiscountPercentage(),
    //   lockeysManagerContract.getLockeyDiscountPercentageOnDebtMarket(),
    // ]);

    // discounts.loanAuctioned = (10000 - Number(loanAuctionedValue)) / 100;
    // discounts.loanListed = (10000 - Number(loanListedValue)) / 100;

    // this.discounts = discounts;

    // CODE TO MOCK
    // discounts.loanAuctioned = 3;
    // discounts.loanListed = 3;

    return discounts;
  }

  async populateLoansFromServer(items: LoanFromServer[]) {
    const nftsToRequest: SimpleNft[] = [];

    const loansDataPromises: Promise<LoanDataFromContract>[] = [];

    items.forEach((item) => {
      const nftsFromItem = item.assets.reduce((acc, asset) => {
        acc.push({
          collection: asset.address,
          tokenId: asset.tokenId,
        });

        return acc;
      }, [] as SimpleNft[]);

      nftsToRequest.push(...nftsFromItem);

      loansDataPromises.push(actionContract.getLoan(item.loanId));
    });

    const nftsWithImage = await getNftsMetadata(nftsToRequest);

    const loansData = await Promise.all(loansDataPromises);

    const currentBorrowAmounts = await Promise.all(
      loansData.map((loanData) =>
        uTokenFactoryContract.getDebtFromLoanId(
          loanData.underlyingAsset,
          loanData.loanId
        )
      )
    );

    return items.map(({ assets, loanId }, index) => {
      const nfts = assets.map(({ address, tokenId, valuation, ltv }) => {
        const nftWithImage = nftsWithImage.find((_nft) =>
          areTheSameNft(_nft, {
            collection: address,
            tokenId: tokenId,
          })
        );

        const image = nftWithImage?.image ?? "";

        return NftFactory.create({
          collection: address,
          tokenId,
          isDeposited: true,
          ltv: BigInt(ltv),
          valuation: BigInt(valuation),
          image,
          owner: loansData[index].owner,
          attributes: [],
          liquidationThreshold: app.DEFAULT_LIQ_THRESHOLD_VALUE,
        });
      });

      return LoanFactory.create({
        id: loanId,
        nfts,
        currentBorrowAmount: currentBorrowAmounts[index],
        underlyingAsset: assets[0].address,
        borrower: loansData[index].owner,
      });
    });
  }

  async retrieveNearLiquidationList(
    offset: number,
    limit: number
  ): Promise<{
    items: Loan[];
    areMoreItemsToLoad: boolean;
  }> {
    let { items } = await unlockdService.retrieveNearLiquidationList(
      offset,
      limit + 1
    );

    if (items.length === 0) return { items: [], areMoreItemsToLoad: false };

    const areMoreItemsToLoad = !!items[limit];

    items = items.slice(0, limit);

    return {
      items: await this.populateLoansFromServer(items),
      areMoreItemsToLoad,
    };
  }

  // TODO: define what to do with repay listener
  async onEvents({
    bid,
    bought,
    cancelOrder,
    redeem,
  }: {
    bid?: { args?: BidArgs; onLogs: (bidTopics: BidTopics) => void };
    bought?: { args?: BidArgs; onLogs: (boughtTopics: BoughtTopics) => void };
    cancelOrder?: {
      args?: CancelArgs;
      onLogs: (cancelTopics: CancelTopics) => void;
    };
    redeem?: {
      args?: RedeemArgs;
      onLogs: (redeemTopics: RedeemTopics) => void;
    };
  }) {
    this.stopEventListeners();

    let unwatchBid: WatchContractEventReturnType | null = null;
    let unwatchOrderCancelled: WatchContractEventReturnType | null = null;
    let unwatchBought: WatchContractEventReturnType | null = null;
    let unwatchRedeemed: WatchContractEventReturnType | null = null;

    if (bid) unwatchBid = await this.onBid(bid);
    if (cancelOrder)
      unwatchOrderCancelled = await this.onOrderCancelled(cancelOrder);
    if (bought) unwatchBought = await this.onBought(bought);
    if (redeem) unwatchRedeemed = await this.onRedeemed(redeem);

    this.addEventListenerStopper(() => {
      unwatchBid?.();
      unwatchOrderCancelled?.();
      unwatchBought?.();
      unwatchRedeemed?.();
    });
  }

  async onBought({
    onLogs,
    args,
  }: {
    args?: BidArgs;
    onLogs: (boughtTopics: BoughtTopics) => void;
  }) {
    const unwatchMarketBought = await marketEventsContract.onBought(
      args || {},
      onLogs
    );

    return () => {
      unwatchMarketBought();
    };
  }

  async onOrderCancelled({
    onLogs,
    args,
  }: {
    args?: CancelArgs;
    onLogs: (cancelTopics: CancelTopics) => void;
  }) {
    const unwatchMarketCancel = await marketEventsContract.onOrderCancelled(
      args || {},
      onLogs
    );

    return () => {
      unwatchMarketCancel();
    };
  }

  async onBid({
    args,
    onLogs,
  }: {
    args?: BidArgs;
    onLogs: (bidTopics: BidTopics) => void;
  }) {
    const unwatchMarketBid = await marketEventsContract.onBid(
      args || {},
      onLogs
    );
    const unwatchAuctionBid = await auctionEventsContract.onBid(
      args || {},
      onLogs
    );

    return () => {
      unwatchMarketBid();
      unwatchAuctionBid();
    };
  }

  async onRedeemed({
    onLogs,
    args,
  }: {
    args?: RedeemArgs;
    onLogs: (redeemTopics: RedeemTopics) => void;
  }) {
    const unwatchRedeemed = await auctionEventsContract.onRedeemed(
      args || {},
      onLogs
    );

    return () => {
      unwatchRedeemed();
    };
  }
}

export default new MarketplaceModule();
