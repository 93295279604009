import { NftWithImageAndName, SimpleNft } from "logic/types/nft/INft";
import { getNftsMetadataBatch } from "../../../utils";
import nftImagesModule from "../../types/nft/NftImagesModule";

type NftWithImageAndMetadata = NftWithImageAndName & {
  attributes: Array<{ trait_type: string; value: string }>;
};

export default async function getNftsMetadata(
  nfts: SimpleNft[]
): Promise<NftWithImageAndMetadata[]> {
  const metadata = await getNftsMetadataBatch(
    nfts.map(({ collection, tokenId }) => ({
      contractAddress: collection,
      tokenId,
    }))
  );

  const images = await nftImagesModule.getNftImagesFromAlchemyMetadata(
    metadata
  );

  const nftsWithImages = nfts.map((nft, i) => ({
    ...nft,
    image: images[i],
    attributes: metadata[i].metadata.attributes,
    name: metadata[i].contractMetadata.name,
  }));

  return nftsWithImages;
}
