import { Address } from "@unlockdfinance/verislabs-web3";
import { OwnedNft } from "alchemy-sdk";
import { app, isMainnet } from "app.config";
import { ConnectionError } from "errors";
import { NftWithImage, SimpleNft } from "logic/types/nft/INft";
import { NftMetadata, getNftsMetadataBatch } from "utils/getNftsMetadataBatch";

class NftImagesModule {
  defaultImage: string;
  imagesStore: Map<Address, Map<string, string>> = new Map();

  constructor(defaultImage: string) {
    this.defaultImage = defaultImage;

    app.COLLECTIONS.forEach((collection) => {
      this.imagesStore.set(collection.address, new Map());
    });
  }

  async getNftsImages(nfts: SimpleNft[]): Promise<NftWithImage[]> {
    const metadata = await getNftsMetadataBatch(
      nfts.map(({ collection, tokenId }) => ({
        contractAddress: collection,
        tokenId,
      }))
    );

    const images = await this.getNftImagesFromAlchemyMetadata(metadata);

    return nfts.map((nft, i) => ({
      ...nft,
      image: images[i],
    }));
  }

  async getNftImageFromTokenUri(
    collection: Address,
    tokenId: string,
    tokenUri: string
  ) {
    const collectionStore = this.imagesStore.get(collection);

    if (collectionStore) {
      const image = collectionStore.get(tokenId);

      if (image) {
        return this.parseUrl(image);
      }
    }

    try {
      return await this.fetchTokenUri(tokenUri);
    } catch (err) {
      // console.log(err);
      return this.defaultImage;
    }
  }

  async getNftImagesFromOwnedNfts(ownedNfts: OwnedNft[]): Promise<string[]> {
    const imagePromises = ownedNfts.map((nft) => {
      const image = nft.image.cachedUrl;

      if (image) return this.parseUrl(image);

      const imageFromRaw = nft.raw.metadata.image;

      if (imageFromRaw) {
        if (imageFromRaw.startsWith("https://")) {
          return this.parseUrl(imageFromRaw);
        } else {
          return this.parseUrl(`https://${this.parseUrl(imageFromRaw)}`);
        }
      }

      const tokenUri = nft.raw.tokenUri;

      if (tokenUri) {
        const collectionAddress = nft.contract.address as Address;

        return this.getNftImageFromTokenUri(
          collectionAddress,
          nft.tokenId,
          tokenUri
        );
      } else {
        return this.defaultImage;
      }
    });

    return await Promise.all(imagePromises);
  }

  async getNftImagesFromAlchemyMetadata(
    metadatas: NftMetadata[]
  ): Promise<string[]> {
    const imagePromises = metadatas.map((nftMetadata) => {
      const image = nftMetadata.media[0]?.gateway;
      const tokenUri = nftMetadata.tokenUri?.gateway!;

      if (image) {
        return this.parseUrl(image);
      } else if (tokenUri) {
        const collectionAddress = nftMetadata.contract.address as Address;

        return this.getNftImageFromTokenUri(
          collectionAddress,
          nftMetadata.id.tokenId,
          tokenUri
        );
      } else {
        return this.defaultImage;
      }
    });

    return await Promise.all(imagePromises);
  }

  async getMoonbirdImage(tokenId: number): Promise<string> {
    const url = `/api/collections/moonbirds/tokens/${tokenId}/images`;

    const response = await fetch(url);

    if (!response.ok) return this.defaultImage;

    const { image } = await response.json();

    return image;
  }

  private async fetchTokenUri(tokenUri: string): Promise<string> {
    const response = await fetch(this.parseUrl(tokenUri));

    if (!response.ok) {
      throw new ConnectionError("failed on fetching ipfs image");
    }

    const { image }: { image: string } = await response.json();

    return this.parseUrl(image);
  }

  private parseUrl(url: string): string {
    if (url.startsWith("ipfs")) {
      return "https://ipfs.io/ipfs/" + url.substring(7);
    }

    return url;
  }
}

export default new NftImagesModule(
  "https://i.seadn.io/gae/9ZLCKS7w0XtwLpq7QVMRgY4Eakg5CQaLa52HFcf4HKksqOxRKc_ybis58FWnjHcNKf5MHc_Iw_JAP_7WPMJFLBLOkhFhBd4HU0InJw"
);
