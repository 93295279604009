import { SimpleNft } from "logic/types/nft/INft";
import { Address } from "viem";

export default function getNftFromNftId(id: string): SimpleNft {
  if (!id.includes("-")) {
    throw new Error(`Invalid NFT id: ${id}`);
  }

  const data = id.split("-");

  if (data.length !== 2) {
    throw new Error(`Invalid NFT id: ${id}`);
  }

  if (data[0].length !== 42 || !data[0].startsWith("0x")) {
    throw new Error(`Invalid collection: ${data[0]}`);
  }

  if (data[1].length === 0) {
    throw new Error(`Invalid token id: ${data[1]}`);
  }

  return {
    collection: data[0] as Address,
    tokenId: data[1],
  };
}
