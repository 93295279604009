import { WalletType } from "contracts/types";
import { MarketItemData } from "./INft";
import { Nft } from "./Nft";
import {
  Address,
  OptionsWriteMethod,
  Output,
} from "@unlockdfinance/verislabs-web3";
import { Attribute } from "./Attribute";

export default class NftErc721 extends Nft {
  constructor(
    collection: Address,
    tokenId: string,
    image: string,
    valuation: bigint,
    ltv: bigint,
    liquidationThreshold: bigint,
    isDeposited: boolean,
    owner: Address,
    attributes: Attribute[],
    marketItemData?: MarketItemData
  ) {
    super(
      collection,
      tokenId,
      image,
      valuation,
      ltv,
      liquidationThreshold,
      isDeposited,
      owner,
      attributes,
      marketItemData
    );
  }

  transfer(
    from: Address,
    to: Address,
    options?: OptionsWriteMethod,
    walletType?: WalletType
  ): Promise<Output<void>> {
    return this._collection.contract.transferFrom(
      from,
      to,
      BigInt(this.tokenId),
      walletType,
      options
    );
  }
}
