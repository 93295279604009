import {
  Address,
  OptionsWriteMethod,
  VerisContract,
} from "@unlockdfinance/verislabs-web3";
import { Erc20Currency } from "../currency/Currency";
import {
  BulkApprovalStatus,
  BulkSubsCallback,
  CollectionCategory,
  IErc721Collection,
} from "./ICollection";
import { ERC721Contract } from "contracts/ERC721Contract";
import { app } from "app.config";
import { externalWalletModule } from "clients/verisModule";
import { Abi } from "viem";
import { customERC721Abi } from "contracts/abis/customERC721";
import { WalletType } from "contracts/types";
import unlockdWalletModule from "logic/UnlockdWalletModule";

export abstract class Collection<TAbi extends Abi> {
  name: string;
  address: Address;
  genericImage: string;
  currenciesSupported: Erc20Currency[];
  category: CollectionCategory;
  abstract contract: VerisContract<TAbi>;

  constructor({
    address,
    currenciesSupported,
    genericImage,
    name,
    category,
  }: {
    name: string;
    address: Address;
    genericImage: string;
    currenciesSupported: Erc20Currency[];
    category: CollectionCategory;
  }) {
    this.name = name;
    this.address = address;
    this.genericImage = genericImage;
    this.currenciesSupported = currenciesSupported;
    this.category = category;
  }
}

export class Erc721Collection
  extends Collection<typeof customERC721Abi>
  implements IErc721Collection
{
  contract: ERC721Contract;
  bulkTransferApprovals: Map<Address, BulkApprovalStatus> = new Map();
  subscriptions: Map<Address, BulkSubsCallback[]> = new Map();

  constructor({
    address,
    currenciesSupported,
    genericImage,
    name,
    category,
  }: {
    name: string;
    address: Address;
    genericImage: string;
    currenciesSupported: Erc20Currency[];
    category: CollectionCategory;
  }) {
    super({ address, currenciesSupported, genericImage, name, category });

    this.contract = new ERC721Contract(this.address);
  }

  async getBulkApprovalStatus(address: Address): Promise<BulkApprovalStatus> {
    if (!this.bulkTransferApprovals.has(address)) {
      const isApproved = await this.contract.isApprovedForAll(
        address,
        app.CONTRACT_ADDRESSES.bulkTransfer
      );

      const status = isApproved
        ? BulkApprovalStatus.APPROVED
        : BulkApprovalStatus.PENDING;

      this.setApprovalStatus(address, status);
    }

    return this.bulkTransferApprovals.get(address)!;
  }

  getBulkApprovalStatusSync(address: Address) {
    return (
      this.bulkTransferApprovals.get(address) || BulkApprovalStatus.PENDING
    );
  }

  private setApprovalStatus(
    address: Address,
    bulkApprovalStatus: BulkApprovalStatus
  ) {
    this.bulkTransferApprovals.set(address, bulkApprovalStatus);

    if (this.subscriptions.has(address)) {
      this.subscriptions
        .get(address)!
        .forEach((callback) => callback(bulkApprovalStatus));
    }
  }

  async approveBulkTransfer(
    walletType: WalletType = WalletType.BASIC,
    options?: OptionsWriteMethod
  ) {
    const address =
      walletType === WalletType.BASIC
        ? externalWalletModule.address!
        : unlockdWalletModule.unlockdAddress!;

    const _options = {
      ...options,
      onLoading: () => {
        options?.onLoading?.();

        this.setApprovalStatus(address, BulkApprovalStatus.APPROVING);
      },
    };

    await this.contract.setApprovalForAll(
      app.CONTRACT_ADDRESSES.bulkTransfer,
      walletType,
      _options
    );

    this.setApprovalStatus(address, BulkApprovalStatus.APPROVED);
  }

  subscribe(address: Address, callback: BulkSubsCallback) {
    if (!this.subscriptions.has(address)) {
      this.subscriptions.set(address, [callback]);
    }

    this.subscriptions.get(address)!.push(callback);

    return () => this.unsubscribe(address, callback);
  }

  unsubscribe(address: Address, callback: BulkSubsCallback) {
    if (!this.subscriptions.has(address)) return;

    const index = this.subscriptions
      .get(address)!
      .findIndex((_callback) => callback === _callback);

    if (index > -1) {
      this.subscriptions.get(address)!.splice(index, 1);
    }
  }
}
