import { getAltForNft } from "../../helpers";
import { Address } from "viem";
import { WalletType } from "../../../contracts/types";
import { bulkTransferContract } from "../../../contracts";
import { alchemy } from "../../../clients";
import getMaximumBidFromReservoir from "../../helpers/getMaximumBidFromReservoir";
import unlockdWalletModule from "../../UnlockdWalletModule";
import { OptionsWriteMethod, Output } from "@unlockdfinance/verislabs-web3";
import { externalWalletModule } from "../../../clients/verisModule";
import { MarketItemType } from "contracts/MarketContract";
import collectionsModule from "logic/CollectionsModule";
import currenciesModule from "logic/CurrenciesModule";
import { INft, MarketItemData, OwnerType } from "./INft";
import { equalIgnoreCase } from "@unlockdfinance/verislabs-web3/utils";
import { IErc721Collection } from "../collection/ICollection";
import { calculateAvailableToBorrowByNft } from "logic/helpers/math";
import createAssetId from "logic/helpers/createAssetId";
import { Attribute } from "./Attribute";

export abstract class Nft implements INft {
  readonly _collection: IErc721Collection;
  readonly tokenId: string;
  readonly image: string;
  readonly valuation: bigint;
  readonly ltv: bigint;
  readonly liquidationThreshold: bigint;
  readonly alt: string;
  readonly availableToBorrow: bigint;
  readonly isDeposited: boolean;
  readonly owner: Address;
  readonly marketItemData?: MarketItemData;
  readonly attributes: Attribute[];
  private _title?: string | null;

  static bulkTransfer(
    nfts: Nft[],
    to: Address,
    options?: OptionsWriteMethod,
    walletType: WalletType = WalletType.BASIC
  ) {
    if (nfts.length === 0) {
      throw new Error("at least one nfts must be passed to transfer");
    }

    // parse the nfts to adapt them to the contract method type
    const nftsParsed = nfts.map(({ collection, tokenId }) => ({
      contractAddress: collection,
      tokenId: BigInt(tokenId),
    }));

    // check if there are cryptopunks included in the nfts array
    const areCryptopunks = nfts.some(({ isCryptopunk }) => isCryptopunk);

    const args: [
      { contractAddress: Address; tokenId: bigint }[],
      Address,
      OptionsWriteMethod | undefined,
      WalletType
    ] = [nftsParsed, to, options, walletType];

    return areCryptopunks
      ? bulkTransferContract.batchPunkTransferFrom(...args)
      : bulkTransferContract.batchTransferFrom(...args);
  }

  constructor(
    collection: Address,
    tokenId: string,
    image: string,
    valuation: bigint,
    ltv: bigint,
    liquidationThreshold: bigint,
    isDeposited: boolean,
    owner: Address,
    attributes: Attribute[],
    marketItemData?: MarketItemData
  ) {
    this._collection = collectionsModule.getCollectionByAddress(collection);
    this.tokenId = tokenId;
    this.image = image;
    this.valuation = valuation;
    this.ltv = ltv;
    this.liquidationThreshold = liquidationThreshold;
    this.isDeposited = isDeposited;
    this.owner = owner;

    this.alt = getAltForNft(this.name, this.tokenId);

    this.availableToBorrow = calculateAvailableToBorrowByNft(
      this.valuation,
      this.ltv
    );

    this.attributes = attributes;

    this.marketItemData = marketItemData;
  }

  get assetId() {
    return createAssetId(this.collection, this.tokenId);
  }

  get name() {
    return this._collection.name;
  }

  get collection() {
    return this._collection.address;
  }

  get isErc721() {
    return true;
  }

  get isCryptopunk() {
    return false;
  }

  get notValuated() {
    return this.valuation === BigInt(0);
  }

  getValuationInUsd(): Promise<bigint> {
    return this.currency.parseToDollar(this.valuation);
  }

  getAvToBorrowInUsd(): Promise<bigint> {
    return this.currency.parseToDollar(this.availableToBorrow);
  }

  get ownerType(): OwnerType {
    return externalWalletModule.address &&
      equalIgnoreCase(this.owner, externalWalletModule.address)
      ? OwnerType.EXTERNAL
      : unlockdWalletModule.unlockdAddress &&
        equalIgnoreCase(this.owner, unlockdWalletModule.unlockdAddress)
      ? OwnerType.UNLOCKD
      : OwnerType.OTHER;
  }

  get nftId() {
    return `${this.collection}-${this.tokenId}`;
  }

  get title(): Promise<string> {
    return new Promise<string>(async (resolve) => {
      if (this._title) resolve(this._title);

      const alchemyNft = await alchemy.nft.getNftMetadata(
        this.collection,
        this.tokenId
      );

      this._title = alchemyNft.name || `${this.name} #${this.tokenId}`;

      // @ts-ignore
      resolve(this._title);
    });
  }

  get currency() {
    return this._collection.currenciesSupported[0];
  }

  get isListed(): boolean {
    return !!this.marketItemData;
  }

  get isCancelListAvailable(): boolean {
    if (!this.isListed) return false;
    if (
      !this.marketItemData ||
      !this.marketItemData.bids ||
      !this.marketItemData.type ||
      !this.marketItemData.biddingEnd
    )
      return false;
    if (this.marketItemData.type === MarketItemType.TYPE_LIQUIDATION_AUCTION)
      return false;
    if (this.marketItemData.type === MarketItemType.TYPE_FIXED_PRICE)
      return true;
    if (this.marketItemData.biddingEnd > Date.now()) return true;
    if (this.marketItemData.bids.length === 0) return true;

    return false;
  }

  get nameToShow(): string {
    return `${this.name} #${this.tokenId}`;
  }

  async getReservoirBid(): Promise<{
    amount: bigint;
    netAmount: bigint;
    id: string;
  } | null> {
    const bidInWeth = await getMaximumBidFromReservoir(this);

    if (!bidInWeth || this.currency.isWeth) return bidInWeth;

    const ethCurrency = currenciesModule.nativeCurrency;

    const amountInUsd = await ethCurrency.parseToDollar(bidInWeth.amount);
    const netAmountInUsd = await ethCurrency.parseToDollar(bidInWeth.netAmount);

    const amountInNftCurrency = await this.currency.parseFromDollar(
      amountInUsd
    );
    const netAmountInNftCurrency = await this.currency.parseFromDollar(
      netAmountInUsd
    );

    return {
      ...bidInWeth,
      amount: amountInNftCurrency,
      netAmount: netAmountInNftCurrency,
      id: bidInWeth.id,
    };
  }

  abstract transfer(
    from: Address,
    to: Address,
    options?: OptionsWriteMethod,
    walletType?: WalletType
  ): Promise<Output<void>>;
}
