import { SimpleNft } from "logic/types/nft/INft";
import { ServerError, ClientError, UnexpectedError } from "../../errors";

export type ReservoirBid = {
  amount: bigint;
  id: string;
  netAmount: bigint;
};

export default async function getMaximumBidFromReservoir(
  nft: SimpleNft
): Promise<ReservoirBid | null> {
  const url = `/api/collections/${nft.collection}/tokens/${nft.tokenId}/bids/max-reservoir`;

  const response = await fetch(url, { headers: { cache: "no-store" } });

  if (!response.ok) {
    const body = await response.text();

    if (response.status > 499) throw new ServerError(body);
    else if (response.status > 399) throw new ClientError(body);

    throw new UnexpectedError(body);
  }

  const maxBid = (await response.json()) as {
    amount: string;
    id: string;
    netAmount: string;
  };

  if (!maxBid) return null;

  return {
    amount: BigInt(maxBid.amount),
    id: maxBid.id,
    netAmount: BigInt(maxBid.netAmount),
  };
}
