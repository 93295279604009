import { app } from "app.config";
import { Erc721Collection } from "./types/collection/Collection";
import currenciesModule from "./CurrenciesModule";
import { Address } from "@unlockdfinance/verislabs-web3";
import { equalIgnoreCase } from "@unlockdfinance/verislabs-web3/utils";

class CollectionModule {
  collections: Erc721Collection[] = [];

  constructor() {
    this.collections = app.COLLECTIONS.map((collectionData) => {
      const currenciesSupported = collectionData.currenciesSupported.map(
        ({ address }) => currenciesModule.getErc20CurrencyByAddress(address)!
      );

      return new Erc721Collection({ ...collectionData, currenciesSupported });
    });
  }

  getCollectionByAddress(address: Address) {
    const collection = this.collections.find((collection) =>
      equalIgnoreCase(collection.address, address)
    );

    if (!collection) {
      throw new Error("Collection not found");
    }

    return collection;
  }

  getCollectionsByCurrencyAddress(currencyAddress: Address) {
    return this.collections.filter((collection) =>
      collection.currenciesSupported.some((currency) =>
        equalIgnoreCase(currency.address, currencyAddress)
      )
    );
  }
}

export default new CollectionModule();
