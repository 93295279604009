import NftErc721 from "./NftErc721";
import { faker } from "@faker-js/faker";
import { app } from "app.config";
import { Address } from "@unlockdfinance/verislabs-web3";
import { INft, MarketItemData } from "./INft";
import { Attribute } from "./Attribute";

export default class NftFactory {
  static create({
    collection,
    tokenId,
    image,
    valuation,
    ltv,
    liquidationThreshold,
    isDeposited,
    owner,
    attributes,
    marketItemData,
  }: {
    collection: Address;
    tokenId: string;
    image: string;
    valuation: bigint;
    ltv: bigint;
    liquidationThreshold: bigint;
    isDeposited: boolean;
    owner: Address;
    attributes: Attribute[];
    marketItemData?: MarketItemData;
  }): INft {
    return new NftErc721(
      collection,
      tokenId,
      image,
      valuation,
      ltv,
      liquidationThreshold,
      isDeposited,
      owner,
      attributes,
      marketItemData
    );
  }

  static clone(nft: INft): INft {
    return this.create({ ...nft, collection: nft.collection });
  }

  static createRandomNft(): INft {
    const _collection =
      app.COLLECTIONS[
        faker.number.int({
          min: 0,
          max: app.COLLECTIONS.length - 1,
        })
      ];

    const collection = _collection.address;
    const tokenId = faker.number.int({ min: 1, max: 1000 }).toString();
    const image = _collection.genericImage;
    const valuation = BigInt(faker.number.bigInt({ min: 1e7, max: 1e8 }));
    const ltv = BigInt(faker.number.bigInt({ min: 1000, max: 7500 }));
    const liquidationThreshold = BigInt(
      faker.number.bigInt({ min: 1000, max: 7500 })
    );
    const isDeposited = faker.datatype.boolean();

    return this.create({
      collection,
      tokenId,
      image,
      valuation,
      ltv,
      liquidationThreshold,
      owner: "0x",
      attributes: [],
      isDeposited,
    });
  }
}
