/**
 * Abstraction of a functional module, where calls to actions
 * and reactions (listeners) are implemented in order to satisfy
 * a logical functionality.
 */
abstract class Module {
  private eventListenerStoppers: (() => void)[] = [];
  private processOn: boolean[] = [];

  protected addEventListenerStopper(stop: () => void) {
    this.eventListenerStoppers.push(stop);
  }

  stopEventListeners() {
    this.eventListenerStoppers.forEach((stop) => stop());

    this.eventListenerStoppers.length = 0;
  }

  abstract onEvents(listeners: {
    [key: string]: [listener: (data: { [key: string]: [value: any] }) => void];
  }): void;

  protected startNewProcess(): number {
    return this.processOn.push(true) - 1;
  }

  protected isProcessOn(pid: number): boolean {
    return this.processOn[pid]
  }

  stopProcesses() {
    for (const i in this.processOn) {
      this.processOn[i] = false;
    }
  }
}

export default Module;
